import React, { useState, useEffect } from "react";
import "./table.css";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";

const Table = ({ value }) => {
  const [tableData, setTableData] = useState(null);
  const [editableRowId, setEditableRowId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const superUser = localStorage.getItem("is_superuser");
  const user = localStorage.getItem("first_name");
  const userId = localStorage.getItem("id");
  const [rowDialog, setRowDialog] = useState(null);
  const [agents, setAgents] = useState(null);
  const [isCategory, setIsCategory] = useState(null);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchCRMData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      const { data } = response;

      setTableData(data?.results);
      setTotalData(data?.count);
      setNextPageUrl(data?.next);
      setPrevPageUrl(data?.previous);
      setLoading(false);

      // setDataCount(dataCount + 20);
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  useEffect(() => {
    fetchCRMData("https://crm.voyagerstales.com/leads/api/?limit=20&offset=0");
    getAgents();
    getCategories();
  }, []);

  const getAgents = async () => {
    try {
      const res = await axios.get(
        "https://crm.voyagerstales.com/account/list",
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );

      setAgents(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getCategories = async () => {
    try {
      const res = await axios.get(
        "https://crm.voyagerstales.com/categories/api",
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );

      setIsCategory(res?.data?.results);
    } catch (err) {
      console.log(err);
    }
  };

  const editInput = (id) => {
    const newData = tableData?.find((data) => data?.id === id);
    setEditedData(newData);
    setEditableRowId(id);
    setRowDialog(id);
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const saveInput = async () => {
    try {
      const res = await axios.patch(
        `https://crm.voyagerstales.com/leads/api/${editedData.id}/`,
        editedData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      // Update the tableData state with the updated data
      setTableData((prevData) => {
        return prevData.map((item) => {
          if (item.id === editedData.id) {
            return res.data; // Updated data from the response
          }
          return item;
        });
      });
      if (searchedData !== null) {
        handleSearch();
      }
      // getAcceptedObjects()
      setEditableRowId(null); // Reset editable row after saving
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.data && error.response.data.error) {
        // Show the error message in a dialog box
        window.alert(error.response.data.error);
      } else {
        window.alert("An error has occured");
      }
    }
  };

  const handleDeleteLead = async (id) => {
    try {
      await axios.delete(`https://crm.voyagerstales.com/leads/api/${id}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      fetchCRMData(
        "https://crm.voyagerstales.com/leads/api/?limit=20&offset=0"
      );
      setCurrentPage(1);
    } catch (err) {
      console.log(err);
    }
  };

  const [inputValue, setInputValue] = useState("");
  const [searchedData, setSearchedData] = useState(null);

  // Function to handle input change
  const handleChange = async (event) => {
    setInputValue(event.target.value);
    const value = event.target.value;
  };

  const [openDialog, setOpenDialog] = useState({
    rowId: null,
    fieldName: null,
  });

  // const getAcceptedObjects = () => {
  //   return tableData?.filter((item) => item?.category_name === filterOption);
  // };

  // Call getAcceptedObjects to get the filtered array
  // const acceptedObjects = getAcceptedObjects();

  const handleSearch = async () => {
    if (inputValue) {
      try {
        const response = await axios.get(
          `https://crm.voyagerstales.com/leads/api?phone_number=${inputValue}`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response?.data?.results?.[0]) {
          setSearchedData(response?.data?.results?.[0]);
        } else {
          window.alert("Please Enter valid Number");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSearchClear = () => {
    setInputValue("");
    setSearchedData(null);
  };

  const [open, setOpen] = useState(false);

  // const handleRowDialog = (rowId) => {
  //   setOpenDialog(rowId);
  // };

  const handleVisibleDialog = (rowId, fieldName) => {
    setOpenDialog({ rowId, fieldName });
  };

  const handleRowClose = () => {
    setRowDialog(null);
    setEditableRowId(null);
  };

  const handleClose = () => {
    setOpenDialog({ rowId: null, fieldName: null });
  };

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(totalData / 20);

  // Calculate the range of items to display on the current page
  const startIndex = (currentPage - 1) * 20;
  const endIndex = Math.min(startIndex + 20, totalData);

  // const convertToHttps = (url) => {
  //   if(url.starts)
  // }

  // Function to handle page navigation
  const goToPage = (page) => {
    setCurrentPage(Math.min(Math.max(1, page), totalPages));
    if (nextPageUrl) {
      const updatedUrl = nextPageUrl.slice(0, 4) + "s" + nextPageUrl.slice(4);
      fetchCRMData(updatedUrl);
    }
  };

  const goToPrevPage = (page) => {
    setCurrentPage(Math.min(Math.max(1, page), totalPages));
    if (prevPageUrl) {
      const updatedUrl = prevPageUrl.slice(0, 4) + "s" + prevPageUrl.slice(4);
      fetchCRMData(updatedUrl);
    }
  };

  const history = useNavigate();

  const create = () => {
    history("/NewLeads");
  };

  const openSkype = (number) => {
    window.open(`skype:${number}?call`)
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : tableData?.length ? (
        <div className="table">
          <h5>Your Leads</h5>
          <div style={{ display: "flex" }}>
            <input
              id="inputField"
              onChange={handleChange}
              value={inputValue}
              type="number"
              className="form-control"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <button className="searchBtn" onClick={handleSearch}>
              Search
            </button>
            <button className="clearBtn" onClick={handleSearchClear}>
              Clear
            </button>
            <button
              style={{
                backgroundColor: "#234674",
                width: "15rem",
                marginLeft: "1rem",
                height: "2.5rem",
                color: "white",
                borderRadius: "15px",
                "&:hover": {
                  backgroundColor: "#234674",
                  color: "white",
                },
              }}
              onClick={create}
            >
              Create New Lead
            </button>
          </div>

          <table className="table table-bordered table-hover">
            <thead className="table-light">
              <tr>
                <th scope="col">Owner Name</th>
                {/* <th scope="col">Property Name</th> */}
                <th scope="col">Country</th>
                {/* <th scope="col">URL</th> */}
                <th scope="col">Contact Number</th>
                {/* <th scope="col">Description</th> */}
                <th scope="col">Email</th>
                {/* <th scope="col">Email</th> */}
                <th scope="col">Status</th>
                {/* <th scope="col">Country</th> */}
                <th scope="col">Added on</th>
                {/* <th scope="col">Phone Number</th> */}
                <th scope="col">Updated on</th>
                {/* <th scope="col">Agent Name</th> */}
                <th scope="col">Property Name</th>
                {/* <th scope="col">Status</th> */}
                <th scope="col">URL</th>
                {/* <th scope="col">Added on</th> */}
                <th scope="col">Description</th>
                <th scope="col">Agent Name</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {searchedData ? (
                editableRowId === searchedData?.id ? (
                  <Dialog
                    sx={{
                      "& .MuiDialog-paper": {
                        minWidth: "100%",
                      },
                    }}
                    open={rowDialog === searchedData?.id}
                    onClose={handleRowClose}
                  >
                    <DialogTitle>Edit Data</DialogTitle>
                    <DialogContent>
                      <thead className="table-light">
                        <tr
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: superUser === "true" ? "243%" : "267%",
                          }}
                        >
                          <th id="edit-th" scope="col">
                            Owner Name
                          </th>
                          {/* <th scope="col">Property Name</th> */}
                          <th
                            id="edit-th"
                            style={{ marginLeft: "1rem" }}
                            scope="col"
                          >
                            Country
                          </th>
                          {/* <th scope="col">URL</th> */}
                          <th
                            id="edit-th"
                            style={{ marginLeft: ".5rem" }}
                            scope="col"
                          >
                            Contact Number
                          </th>
                          {/* <th scope="col">Description</th> */}
                          <th
                            id="edit-th"
                            style={{ marginLeft: "1rem" }}
                            scope="col"
                          >
                            Email
                          </th>
                          {/* <th scope="col">Email</th> */}
                          <th id="edit-th" scope="col">
                            Status
                          </th>
                          {/* <th scope="col">Country</th> */}
                          {/* <th scope="col">Phone Number</th> */}
                          {/* <th scope="col">Agent Name</th> */}
                          <th
                            id="edit-th"
                            style={{ marginLeft: "1rem" }}
                            scope="col"
                          >
                            Property Name
                          </th>
                          {/* <th scope="col">Status</th> */}
                          <th id="edit-th" scope="col">
                            URL
                          </th>
                          {/* <th scope="col">Added on</th> */}
                          <th id="edit-th" scope="col">
                            Description
                          </th>
                          {superUser === "true" && (
                            <th
                              id="edit-th"
                              scope="col"
                              style={{ marginLeft: ".5rem" }}
                            >
                              Agent Name
                            </th>
                          )}
                          <th id="edit-th" scope="col">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tr
                        key={searchedData?.id}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          marginTop: "1rem",
                        }}
                      >
                        <td>
                          <textarea
                            type="text"
                            rows={3}
                            value={editedData?.first_name}
                            onChange={(e) => handleInputChange(e, "first_name")}
                            style={{
                              width: "80%",
                              fontSize: "14px",
                              maxHeight: "350px",
                            }}
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            value={editedData?.country}
                            onChange={(e) => handleInputChange(e, "country")}
                            style={{ width: "80%", fontSize: "14px" }}
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            value={editedData?.phone_number}
                            onChange={(e) =>
                              handleInputChange(e, "phone_number")
                            }
                            style={{ width: "80%", fontSize: "14px" }}
                          />
                        </td>

                        <td>
                          <textarea
                            type="text"
                            value={editedData?.email}
                            onChange={(e) => handleInputChange(e, "email")}
                            rows={4}
                            style={{
                              width: "80%",
                              fontSize: "14px",
                              maxHeight: "350px",
                            }}
                          />
                        </td>

                        <td>
                          <select
                            onChange={(e) => handleInputChange(e, "category")}
                          >
                            <option value="">Select Category</option>
                            {isCategory?.map((category) => (
                              <option key={category?.id} value={category?.id}>
                                {category?.name}
                              </option>
                            ))}
                          </select>
                          {/* <input
                            type="text"
                            value={editedData.category_name}
                            onChange={(e) =>
                              handleInputChange(e, "category_name")
                            }
                            style={{width:"80%", fontSize:"14px"}}
                          /> */}
                        </td>

                        <td>
                          <textarea
                            type="text"
                            rows={4}
                            value={editedData?.hotel_name}
                            onChange={(e) => handleInputChange(e, "hotel_name")}
                            style={{
                              width: "80%",
                              fontSize: "14px",
                              maxHeight: "350px",
                            }}
                          />
                        </td>
                        <td>
                          <textarea
                            type="text"
                            rows={4}
                            value={editedData?.url}
                            onChange={(e) => handleInputChange(e, "url")}
                            style={{
                              width: "80%",
                              fontSize: "14px",
                              maxHeight: "350px",
                            }}
                          />
                        </td>
                        <td>
                          <textarea
                            type="text"
                            rows={4}
                            value={editedData?.description}
                            onChange={(e) =>
                              handleInputChange(e, "description")
                            }
                            style={{
                              width: "80%",
                              fontSize: "14px",
                              maxHeight: "350px",
                            }}
                          />
                        </td>

                        {superUser === "true" && (
                          <td>
                            <select
                              onChange={(e) => handleInputChange(e, "agent")}
                            >
                              <option value="">Select Agent</option>
                              {agents?.map((agent) => (
                                <option key={agent?.id} value={agent?.id}>
                                  {agent?.first_name}
                                </option>
                              ))}
                            </select>
                          </td>
                        )}

                        <td>
                          <div>
                            <button
                              className="editBtn leftMarginBtn"
                              onClick={saveInput}
                            >
                              Save
                            </button>
                          </div>
                        </td>
                      </tr>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleRowClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                ) : (
                  <tr key={searchedData?.id}>
                    <td>{searchedData?.first_name}</td>

                    <td>{searchedData?.country}</td>

                    <td>{searchedData?.phone_number}</td>

                    <td className="two-lines" style={{ position: "relative" }}>
                      {searchedData?.email}

                      {<br />}
                      {searchedData?.email?.length > 8 && (
                        <Button
                          sx={{
                            fontSize: "9px",
                            position: "absolute",
                            bottom: "0",
                            right: "0",
                          }}
                          onClick={() =>
                            handleVisibleDialog(searchedData?.id, "email")
                          }
                        >
                          Show
                        </Button>
                      )}
                      <Dialog
                        open={
                          openDialog.rowId === searchedData?.id &&
                          openDialog.fieldName === "email"
                        }
                        onClose={handleClose}
                      >
                        <DialogTitle>Email</DialogTitle>
                        <DialogContent>
                          <Typography>{searchedData?.email}</Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </td>

                    <td className="expandedColumn">
                      <div
                        className={`${
                          searchedData?.category_name === "Accepted"
                            ? "accepted"
                            : searchedData?.category_name == "Rejected"
                            ? "rejected"
                            : searchedData?.category_name === "CallBack"
                            ? "callback"
                            : searchedData?.category_name === "Not connected"
                            ? "not-connected"
                            : searchedData?.category_name === "Closed"
                            ? "closed"
                            : searchedData?.category_name === "Follow up"
                            ? "follow-up"
                            : searchedData?.category_name === "Not Interested"
                            ? "not-interested"
                            : "language-barrier"
                        }`}
                      >
                        {searchedData?.category_name}
                      </div>
                    </td>

                    <td>
                      {new Date(searchedData?.created_at)
                        .toLocaleDateString("en-US", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })
                        .replace(",", "")}
                      <br />
                      {new Date(searchedData?.created_at).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </td>

                    <td>
                      {new Date(searchedData?.updated_at)
                        .toLocaleDateString("en-US", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })
                        .replace(",", "")}
                      <br />
                      {new Date(searchedData?.updated_at).toLocaleTimeString(
                        "en-US",
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </td>

                    <td style={{ position: "relative" }}>
                      {searchedData?.hotel_name}

                      {<br />}
                      {searchedData?.hotel_name?.length > 8 && (
                        <Button
                          sx={{
                            fontSize: "9px",
                            position: "absolute",
                            bottom: "0",
                            right: "0",
                          }}
                          onClick={() =>
                            handleVisibleDialog(searchedData?.id, "hotel_name")
                          }
                        >
                          Show
                        </Button>
                      )}
                      <Dialog
                        open={
                          openDialog.rowId === searchedData?.id &&
                          openDialog.fieldName === "hotel_name"
                        }
                        onClose={handleClose}
                      >
                        <DialogTitle>Property Name</DialogTitle>
                        <DialogContent>
                          <Typography>{searchedData?.hotel_name}</Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </td>
                    <td style={{ position: "relative" }}>
                      <a href={searchedData?.url} target="_blank">
                        {searchedData?.url}
                      </a>

                      {<br />}
                      {searchedData?.url?.length > 8 && (
                        <Button
                          sx={{
                            fontSize: "9px",
                            position: "absolute",
                            bottom: "0",
                            right: "0",
                          }}
                          onClick={() =>
                            handleVisibleDialog(searchedData?.id, "url")
                          }
                        >
                          Show
                        </Button>
                      )}
                      <Dialog
                        open={
                          openDialog.rowId === searchedData?.id &&
                          openDialog.fieldName === "url"
                        }
                        onClose={handleClose}
                      >
                        <DialogTitle>URL</DialogTitle>
                        <DialogContent>
                          <Typography>
                            <a href={searchedData?.url} target="_blank">
                              {searchedData?.url}
                            </a>
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </td>
                    <td style={{ position: "relative" }}>
                      {searchedData?.description}

                      {<br />}
                      {searchedData?.description?.length > 8 && (
                        <Button
                          sx={{
                            fontSize: "9px",
                            position: "absolute",
                            bottom: "0",
                            right: "0",
                          }}
                          onClick={() =>
                            handleVisibleDialog(searchedData?.id, "description")
                          }
                        >
                          Show
                        </Button>
                      )}
                      <Dialog
                        open={
                          openDialog.rowId === searchedData?.id &&
                          openDialog.fieldName === "description"
                        }
                        onClose={handleClose}
                      >
                        <DialogTitle>Description</DialogTitle>
                        <DialogContent>
                          <Typography>{searchedData?.description}</Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </td>

                    <td>{searchedData?.agent_first_name}</td>

                    <td>
                      {" "}
                      <div>
                        {editableRowId === searchedData?.id ? (
                          <div>
                            <svg
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.16663 3.66699H2.49996C2.14634 3.66699 1.8072 3.80747 1.55715 4.05752C1.3071 4.30756 1.16663 4.6467 1.16663 5.00033V11.0003C1.16663 11.3539 1.3071 11.6931 1.55715 11.9431C1.8072 12.1932 2.14634 12.3337 2.49996 12.3337H8.49996C8.85358 12.3337 9.19272 12.1932 9.44277 11.9431C9.69282 11.6931 9.83329 11.3539 9.83329 11.0003V10.3337"
                                stroke="#E87B25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.16667 2.3334L11.1667 4.3334M12.09 3.39007C12.3526 3.12751 12.5001 2.77139 12.5001 2.40007C12.5001 2.02875 12.3526 1.67264 12.09 1.41007C11.8274 1.14751 11.4713 1 11.1 1C10.7287 1 10.3726 1.14751 10.11 1.41007L4.5 7.00007V9.00007H6.5L12.09 3.39007Z"
                                stroke="#E87B25"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <button
                              className="editBtn leftMarginBtn"
                              onClick={saveInput}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          (superUser === "true" ||
                            userId == searchedData?.agent) && (
                            <div>
                              <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.16663 3.66699H2.49996C2.14634 3.66699 1.8072 3.80747 1.55715 4.05752C1.3071 4.30756 1.16663 4.6467 1.16663 5.00033V11.0003C1.16663 11.3539 1.3071 11.6931 1.55715 11.9431C1.8072 12.1932 2.14634 12.3337 2.49996 12.3337H8.49996C8.85358 12.3337 9.19272 12.1932 9.44277 11.9431C9.69282 11.6931 9.83329 11.3539 9.83329 11.0003V10.3337"
                                  stroke="#E87B25"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.16667 2.3334L11.1667 4.3334M12.09 3.39007C12.3526 3.12751 12.5001 2.77139 12.5001 2.40007C12.5001 2.02875 12.3526 1.67264 12.09 1.41007C11.8274 1.14751 11.4713 1 11.1 1C10.7287 1 10.3726 1.14751 10.11 1.41007L4.5 7.00007V9.00007H6.5L12.09 3.39007Z"
                                  stroke="#E87B25"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <button
                                className="editBtn leftMarginBtn"
                                onClick={() => editInput(searchedData?.id)}
                              >
                                Edit
                              </button>
                            </div>
                          )
                        )}{" "}
                      </div>
                      {superUser === "true" && (
                        <div>
                          <svg
                            width="11"
                            height="12"
                            viewBox="0 0 11 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.16663 12C1.79996 12 1.48618 11.8696 1.22529 11.6087C0.964404 11.3478 0.833737 11.0338 0.833293 10.6667V2H0.166626V0.666667H3.49996V0H7.49996V0.666667H10.8333V2H10.1666V10.6667C10.1666 11.0333 10.0362 11.3473 9.77529 11.6087C9.5144 11.87 9.2004 12.0004 8.83329 12H2.16663ZM8.83329 2H2.16663V10.6667H8.83329V2ZM3.49996 9.33333H4.83329V3.33333H3.49996V9.33333ZM6.16663 9.33333H7.49996V3.33333H6.16663V9.33333Z"
                              fill="#9C0F0F"
                            />
                          </svg>
                          <button
                            className="deleteButton leftMarginBtn"
                            onClick={() => handleDeleteLead(searchedData?.id)}
                          >
                            delete
                          </button>
                        </div>
                      )}
                      <div>
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 24H6C4.9 24 4 23.1 4 22V2C4 .9 4.9 0 6 0H18C19.1 0 20 .9 20 2V22C20 23.1 19.1 24 18 24ZM6 1C5.45 1 5 1.45 5 2V22C5 22.55 5.45 23 6 23H18C18.55 23 19 22.55 19 22V2C19 1.45 18.55 1 18 1H6ZM15 2H9C8.45 2 8 2.45 8 3V4C8 4.55 8.45 5 9 5H15C15.55 5 16 4.55 16 4V3C16 2.45 15.55 2 15 2Z"
                              fill="#9C0F0F"
                            />
                          </svg>
                          <button className="deleteButton leftMarginBtn" onClick={() => openSkype(searchedData?.phone_number)}>
                            Call
                          </button>
                        </div>
                    </td>
                  </tr>
                )
              ) : (
                tableData?.map((data) =>
                  editableRowId === data.id ? (
                    <Dialog
                      sx={{
                        "& .MuiDialog-paper": {
                          minWidth: "100%",
                        },
                      }}
                      open={rowDialog === data.id}
                      onClose={handleRowClose}
                    >
                      <DialogTitle>Edit Data</DialogTitle>
                      <DialogContent>
                        <thead className="table-light">
                          <tr
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: superUser === "true" ? "243%" : "267%",
                            }}
                          >
                            <th id="edit-th" scope="col">
                              Owner Name
                            </th>
                            {/* <th scope="col">Property Name</th> */}
                            <th
                              id="edit-th"
                              style={{ marginLeft: "1rem" }}
                              scope="col"
                            >
                              Country
                            </th>
                            {/* <th scope="col">URL</th> */}
                            <th
                              id="edit-th"
                              style={{ marginLeft: ".5rem" }}
                              scope="col"
                            >
                              Contact Number
                            </th>
                            {/* <th scope="col">Description</th> */}
                            <th
                              id="edit-th"
                              style={{ marginLeft: "1rem" }}
                              scope="col"
                            >
                              Email
                            </th>
                            {/* <th scope="col">Email</th> */}
                            <th id="edit-th" scope="col">
                              Status
                            </th>
                            {/* <th scope="col">Country</th> */}
                            {/* <th scope="col">Phone Number</th> */}
                            {/* <th scope="col">Agent Name</th> */}
                            <th
                              id="edit-th"
                              style={{ marginLeft: "1rem" }}
                              scope="col"
                            >
                              Property Name
                            </th>
                            {/* <th scope="col">Status</th> */}
                            <th id="edit-th" scope="col">
                              URL
                            </th>
                            {/* <th scope="col">Added on</th> */}
                            <th id="edit-th" scope="col">
                              Description
                            </th>
                            {superUser === "true" && (
                              <th
                                id="edit-th"
                                scope="col"
                                style={{ marginLeft: ".5rem" }}
                              >
                                Agent Name
                              </th>
                            )}
                            <th id="edit-th" scope="col">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tr
                          key={data.id}
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            marginTop: "1rem",
                          }}
                        >
                          <td>
                            <textarea
                              type="text"
                              rows={2}
                              value={editedData.first_name}
                              onChange={(e) =>
                                handleInputChange(e, "first_name")
                              }
                              style={{
                                width: "80%",
                                fontSize: "14px",
                                maxHeight: "350px",
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={editedData.country}
                              onChange={(e) => handleInputChange(e, "country")}
                              style={{ width: "80%", fontSize: "14px" }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={editedData.phone_number}
                              onChange={(e) =>
                                handleInputChange(e, "phone_number")
                              }
                              style={{ width: "80%", fontSize: "14px" }}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              rows={3}
                              value={editedData.email}
                              onChange={(e) => handleInputChange(e, "email")}
                              style={{
                                width: "80%",
                                fontSize: "14px",
                                maxHeight: "350px",
                              }}
                            />
                          </td>
                          <td>
                            <select
                              onChange={(e) => handleInputChange(e, "category")}
                            >
                              <option value="">Select Category</option>
                              {isCategory.map((category) => (
                                <option key={category?.id} value={category?.id}>
                                  {category?.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <textarea
                              type="text"
                              rows={3}
                              value={editedData.hotel_name}
                              onChange={(e) =>
                                handleInputChange(e, "hotel_name")
                              }
                              style={{
                                width: "80%",
                                fontSize: "14px",
                                maxHeight: "350px",
                              }}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              rows={4}
                              value={editedData.url}
                              onChange={(e) => handleInputChange(e, "url")}
                              style={{
                                width: "80%",
                                fontSize: "14px",
                                maxHeight: "350px",
                              }}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              rows={4}
                              value={editedData.description}
                              onChange={(e) =>
                                handleInputChange(e, "description")
                              }
                              style={{
                                width: "80%",
                                fontSize: "14px",
                                maxHeight: "350px",
                              }}
                            />
                          </td>

                          {superUser === "true" && (
                            <td>
                              <select
                                onChange={(e) => handleInputChange(e, "agent")}
                              >
                                <option value="">Select Agent</option>
                                {agents.map((agent) => (
                                  <option key={agent.id} value={agent.id}>
                                    {agent.first_name}
                                  </option>
                                ))}
                              </select>
                            </td>
                          )}

                          <td>
                            <div>
                              <button
                                className="editBtn leftMarginBtn"
                                onClick={saveInput}
                              >
                                Save
                              </button>
                            </div>
                          </td>
                        </tr>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleRowClose}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  ) : (
                    <tr key={data?.id}>
                      <td>{data?.first_name}</td>
                      <td>{data.country}</td>
                      <td className="two-lines">{data.phone_number}</td>
                      <td
                        className="two-lines"
                        style={{ position: "relative" }}
                      >
                        {data.email}
                        {<br />}
                        {data?.email?.length > 8 && (
                          <Button
                            sx={{
                              fontSize: "9px",
                              position: "absolute",
                              bottom: "0",
                              right: "0",
                            }}
                            onClick={() =>
                              handleVisibleDialog(data.id, "email")
                            }
                          >
                            Show
                          </Button>
                        )}
                        <Dialog
                          open={
                            openDialog.rowId === data.id &&
                            openDialog.fieldName === "email"
                          }
                          onClose={handleClose}
                        >
                          <DialogTitle>Email</DialogTitle>
                          <DialogContent>
                            <Typography>{data?.email}</Typography>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                          </DialogActions>
                        </Dialog>
                      </td>
                      <td className="expandedColumn">
                        <div
                          className={`${
                            data?.category_name === "Accepted"
                              ? "accepted"
                              : data?.category_name === "Rejected"
                              ? "rejected"
                              : data?.category_name === "CallBack"
                              ? "callback"
                              : data?.category_name === "Not connected"
                              ? "not-connected"
                              : data?.category_name === "Closed"
                              ? "closed"
                              : data?.category_name === "Follow up"
                              ? "follow-up"
                              : data?.category_name === "Not Interested"
                              ? "not-interested"
                              : "language-barrier"
                          }`}
                        >
                          {data.category_name}
                        </div>
                      </td>
                      <td>
                        {new Date(data.created_at)
                          .toLocaleDateString("en-US", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })
                          .replace(",", "")}
                        <br />
                        {new Date(data.created_at).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                      <td>
                        {new Date(data?.updated_at)
                          .toLocaleDateString("en-US", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })
                          .replace(",", "")}
                        <br />
                        {new Date(data?.updated_at).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </td>
                      <td style={{ position: "relative" }}>
                        {data?.hotel_name}
                        {<br />}
                        {data?.hotel_name?.length > 8 && (
                          <Button
                            sx={{
                              fontSize: "9px",
                              position: "absolute",
                              bottom: "0",
                              right: "0",
                            }}
                            onClick={() =>
                              handleVisibleDialog(data?.id, "hotel_name")
                            }
                          >
                            Show
                          </Button>
                        )}
                        <Dialog
                          open={
                            openDialog.rowId === data.id &&
                            openDialog.fieldName === "hotel_name"
                          }
                          onClose={handleClose}
                        >
                          <DialogTitle>Property Name</DialogTitle>
                          <DialogContent>
                            <Typography>{data?.hotel_name}</Typography>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                          </DialogActions>
                        </Dialog>
                      </td>
                      <td
                        className="two-lines"
                        style={{ position: "relative" }}
                      >
                        <a href={data?.url} target="_blank">
                          {data?.url}
                        </a>
                        {data?.url}
                        {<br />}
                        {data?.url?.length > 8 && (
                          <Button
                            sx={{
                              position: "absolute",
                              fontSize: "9px",
                              bottom: "0",
                              right: "0",
                            }}
                            onClick={() => handleVisibleDialog(data.id, "url")}
                          >
                            Show
                          </Button>
                        )}
                        <Dialog
                          open={
                            openDialog.rowId === data.id &&
                            openDialog.fieldName === "url"
                          }
                          onClose={handleClose}
                        >
                          <DialogTitle>URL</DialogTitle>
                          <DialogContent>
                            <Typography>
                              <a href={data?.url} target="_blank">
                                {data?.url}
                              </a>
                            </Typography>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                          </DialogActions>
                        </Dialog>
                      </td>
                      <td
                        className="two-lines"
                        style={{ position: "relative" }}
                      >
                        {data.description}
                        {<br />}
                        {data?.description?.length > 8 && (
                          <Button
                            sx={{
                              fontSize: "9px",
                              position: "absolute",
                              bottom: "0",
                              right: "0",
                            }}
                            onClick={() =>
                              handleVisibleDialog(data.id, "description")
                            }
                          >
                            Show
                          </Button>
                        )}
                        <Dialog
                          open={
                            openDialog.rowId === data.id &&
                            openDialog.fieldName === "description"
                          }
                          onClose={handleClose}
                        >
                          <DialogTitle>Description</DialogTitle>
                          <DialogContent>
                            <Typography>{data?.description}</Typography>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                          </DialogActions>
                        </Dialog>
                      </td>
                      <td>{data.agent_first_name}</td>
                      <td>
                        {" "}
                        <div>
                          {(superUser === "true" || userId == data?.agent) && (
                            <div>
                              <svg
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.16663 3.66699H2.49996C2.14634 3.66699 1.8072 3.80747 1.55715 4.05752C1.3071 4.30756 1.16663 4.6467 1.16663 5.00033V11.0003C1.16663 11.3539 1.3071 11.6931 1.55715 11.9431C1.8072 12.1932 2.14634 12.3337 2.49996 12.3337H8.49996C8.85358 12.3337 9.19272 12.1932 9.44277 11.9431C9.69282 11.6931 9.83329 11.3539 9.83329 11.0003V10.3337"
                                  stroke="#E87B25"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M9.16667 2.3334L11.1667 4.3334M12.09 3.39007C12.3526 3.12751 12.5001 2.77139 12.5001 2.40007C12.5001 2.02875 12.3526 1.67264 12.09 1.41007C11.8274 1.14751 11.4713 1 11.1 1C10.7287 1 10.3726 1.14751 10.11 1.41007L4.5 7.00007V9.00007H6.5L12.09 3.39007Z"
                                  stroke="#E87B25"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <button
                                className="editBtn leftMarginBtn"
                                onClick={() => editInput(data.id)}
                              >
                                Edit
                              </button>
                            </div>
                          )}{" "}
                        </div>
                        {superUser === "true" && (
                          <div>
                            <svg
                              width="11"
                              height="12"
                              viewBox="0 0 11 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.16663 12C1.79996 12 1.48618 11.8696 1.22529 11.6087C0.964404 11.3478 0.833737 11.0338 0.833293 10.6667V2H0.166626V0.666667H3.49996V0H7.49996V0.666667H10.8333V2H10.1666V10.6667C10.1666 11.0333 10.0362 11.3473 9.77529 11.6087C9.5144 11.87 9.2004 12.0004 8.83329 12H2.16663ZM8.83329 2H2.16663V10.6667H8.83329V2ZM3.49996 9.33333H4.83329V3.33333H3.49996V9.33333ZM6.16663 9.33333H7.49996V3.33333H6.16663V9.33333Z"
                                fill="#9C0F0F"
                              />
                            </svg>
                            <button
                              className="deleteButton leftMarginBtn"
                              onClick={() => handleDeleteLead(data.id)}
                            >
                              delete
                            </button>
                          </div>
                        )}
                        <div>
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 24H6C4.9 24 4 23.1 4 22V2C4 .9 4.9 0 6 0H18C19.1 0 20 .9 20 2V22C20 23.1 19.1 24 18 24ZM6 1C5.45 1 5 1.45 5 2V22C5 22.55 5.45 23 6 23H18C18.55 23 19 22.55 19 22V2C19 1.45 18.55 1 18 1H6ZM15 2H9C8.45 2 8 2.45 8 3V4C8 4.55 8.45 5 9 5H15C15.55 5 16 4.55 16 4V3C16 2.45 15.55 2 15 2Z"
                              fill="#9C0F0F"
                            />
                          </svg>
                          <button className="deleteButton leftMarginBtn" onClick={() => openSkype(data?.phone_number)}>
                            Call
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
          {searchedData === null && (
            <div style={{ marginTop: "20px" }}>
              {/* Pagination controls */}
              <Button
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#232674",
                  padding: "0",
                  marginRight: "10px",
                  "&:hover": { color: "white", backgroundColor: "#232674" },
                }}
                onClick={() => goToPrevPage(currentPage - 1)}
              >
                prev
              </Button>
              <span>{`Page ${currentPage} of ${totalPages}`}</span>
              <Button
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#232674",
                  padding: "0",
                  marginLeft: "10px",
                  "&:hover": { color: "white", backgroundColor: "#232674" },
                }}
                onClick={() => goToPage(currentPage + 1)}
              >
                next
              </Button>
              <div style={{ marginTop: "10px", height: "15px" }}></div>
            </div>
          )}
        </div>
      ) : (
        <div>No Data Found...</div>
      )}
    </>
  );
};

export default Table;
