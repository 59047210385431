import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { CgCloseO } from "react-icons/cg";
import { FaRegEdit } from "react-icons/fa";
import Loading from "../Loading";
import "./Task.css";

const Tasks = () => {
  const [taskData, setTaskData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewtask, setIsNewTask] = useState(false);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [editableRowId, setEditableRowId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [rowDialog, setRowDialog] = useState(null);

  const getTaskData = async () => {
    try {
      const res = await axios.get("https://crm.voyagerstales.com/tasks/api", {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      });
      setTaskData(res?.data?.results);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTaskData();
  }, [editableRowId === null]);

  const createTask = async () => {
    try {
      await axios.post(
        "https://crm.voyagerstales.com/tasks/api/",
        {
          title: taskTitle,
          description: taskDescription,
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      getTaskData();
      setIsNewTask(false);
      setTaskTitle("");
      setTaskDescription("");
    } catch (err) {
      console.log(err);
    }
  };

  const editTask = async () => {
    try {
      const res = await axios.patch(
        `https://crm.voyagerstales.com/tasks/api/${editedData?.id}/`,
        editedData,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      setTaskData((prevData) => {
        return prevData.map((item) => {
          if (item.id === editedData.id) {
            return res?.data?.results; // Updated data from the response
          }
          return item;
        });
      });
      setEditableRowId(null);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteTask = async (id) => {
    try {
      await axios.delete(
        `https://crm.voyagerstales.com/tasks/api/${id}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      getTaskData()
    } catch (err) {
      console.log(err);
    }
  };

  const handleTask = () => {
    setIsNewTask(true);
  };

  const handleNewTask = async (e) => {
    e.preventDefault();
    createTask();
  };

  const handleCloseTaskForm = () => {
    setIsNewTask(false);
    setTaskTitle("");
    setTaskDescription("");
  };

  const handleTastTitleChange = (e) => {
    setTaskTitle(e.target.value);
  };

  const handleTastDescriptionChange = (e) => {
    setTaskDescription(e.target.value);
  };

  const handleEditTask = (id) => {
    const newData = taskData?.find((data) => data?.id === id);
    setEditedData(newData);
    setEditableRowId(id);
    setRowDialog(id);
  };

  const totalPages = Math.ceil(taskData?.length / 10);

  const startIndex = (currentPage - 1) * 10;

  const endIndex = Math.min(startIndex + 10, taskData?.length);

  const goToPage = (page) => {
    setCurrentPage(Math.min(Math.max(1, page), totalPages));
  };

  const goToPrevPage = (page) => {
    setCurrentPage(Math.min(Math.max(1, page), totalPages));
  };

  const handleRowClose = () => {
    setRowDialog(null);
    setEditableRowId(null);
  };

  const handleUpdateTask = async (e) => {
    e.preventDefault();
    editTask();
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isNewtask ? (
        <Box
          sx={{
            width: "80%",
            height: "55%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              height: "100%",
              border: "1px solid black",
              padding: "4%",
              borderRadius: "7%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <h5>Add New Task</h5>
              <CgCloseO
                size={20}
                onClick={handleCloseTaskForm}
                className="close-btn"
              />
            </Box>
            <Box sx={{}}>
              <form onSubmit={handleNewTask}>
                <Box
                  gap={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "2%",
                  }}
                >
                  <TextField
                    label="Task Title"
                    variant="outlined"
                    autoComplete="false"
                    type="text"
                    name="title"
                    required
                    value={taskTitle}
                    onChange={(e) => handleTastTitleChange(e)}
                    inputProps={{ style: { padding: "0", margin: "0" } }}
                    InputLabelProps={{
                      style: { marginTop: "0", fontSize: "15px" },
                    }}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: "3rem",
                        borderRadius: ".625rem",
                        padding: "5%",
                      },
                    }}
                  />

                  <TextField
                    label="Task Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    autoComplete="false"
                    type="text"
                    name="description"
                    required
                    value={taskDescription}
                    onChange={(e) => handleTastDescriptionChange(e)}
                    InputLabelProps={{ style: { marginTop: "0" } }}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: "6rem",
                        borderRadius: ".625rem",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    //   endIcon={<ArrowForwardOutlinedIcon />}
                    sx={{
                      my: "2rem",
                      textTransform: "capitalize",
                      backgroundColor: "#232674",
                      color: "white",
                      width: "80%",
                      height: "3rem",
                      fontSize: "1rem",
                      fontWeight: "400",
                      borderRadius: ".625rem",
                      "&:hover": {
                        backgroundColor: "#232674",
                        color: "white",
                      },
                    }}
                  >
                    Create
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ width: "80%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "2%",
            }}
          >
            <h5>Your Tasks</h5>
            <Button
              sx={{
                backgroundColor: "#232674",
                color: "#ffffff",
                padding: "1rem",
                borderRadius: "15px",
                "&:hover": {
                  backgroundColor: "#232674",
                  color: "#ffffff",
                },
              }}
              onClick={handleTask}
            >
              <Typography sx={{ textTransform: "none" }}>New Task</Typography>
            </Button>
          </Box>
          {taskData.length > 0 ? (
            taskData?.slice(startIndex, endIndex)?.map((task) =>
              editableRowId === task?.id ? (
                <Dialog
                  sx={{
                    "& .MuiDialog-paper": {
                      minWidth: "30%",
                      borderRadius: "5%",
                    },
                  }}
                  open={rowDialog === task?.id}
                  onClose={handleRowClose}
                >
                  <DialogTitle>Edit Task</DialogTitle>
                  <DialogContent>
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        border: "1px solid black",
                        padding: "4%",
                        borderRadius: "7%",
                        paddingTop:"8%",
                      }}
                    >
                      <Box sx={{}}>
                        <form onSubmit={handleUpdateTask}>
                          <Box
                            gap={3}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "2%",
                            }}
                          >
                            <TextField
                              label="Task Title"
                              variant="outlined"
                              autoComplete="false"
                              type="text"
                              name="title"
                              required
                              value={editedData?.title}
                              onChange={(e) => handleInputChange(e, "title")}
                              inputProps={{
                                style: { padding: "0", margin: "0" },
                              }}
                              InputLabelProps={{
                                style: { marginTop: "0", fontSize: "15px" },
                              }}
                              sx={{
                                width: "100%",
                                "& .MuiInputBase-root": {
                                  height: "3rem",
                                  borderRadius: ".625rem",
                                  padding: "5%",
                                },
                              }}
                            />

                            <TextField
                              label="Task Description"
                              variant="outlined"
                              multiline
                              rows={4}
                              autoComplete="false"
                              type="text"
                              name="description"
                              required
                              value={editedData?.description}
                              onChange={(e) =>
                                handleInputChange(e, "description")
                              }
                              InputLabelProps={{ style: { marginTop: "0" } }}
                              sx={{
                                width: "100%",
                                "& .MuiInputBase-root": {
                                  height: "6rem",
                                  borderRadius: ".625rem",
                                },
                              }}
                            />
                          </Box>
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Button
                              type="submit"
                              //   endIcon={<ArrowForwardOutlinedIcon />}
                              sx={{
                                my: "2rem",
                                textTransform: "capitalize",
                                backgroundColor: "#232674",
                                color: "white",
                                width: "80%",
                                height: "3rem",
                                fontSize: "1rem",
                                fontWeight: "400",
                                borderRadius: ".625rem",
                                "&:hover": {
                                  backgroundColor: "#232674",
                                  color: "white",
                                },
                              }}
                            >
                              Update
                            </Button>
                          </Box>
                        </form>
                      </Box>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button  onClick={handleRowClose}>Close</Button>
                  </DialogActions>
                </Dialog>
              ) : (
                <Box
                  key={task?.id}
                  sx={{
                    width: "100%",
                    height: "150px",
                    border: "1px solid black",
                    borderRadius: "30px",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "2%",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "30%",
                      borderBottom: "1px solid black",
                      paddingLeft: "4%",
                      paddingTop: "1%",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingRight: "4%",
                      backgroundColor: "#e4fcec",
                      borderTopLeftRadius: "30px",
                      borderTopRightRadius: "30px",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Typography variant="body1">{`(${task?.user?.first_name})`}</Typography>
                      &nbsp;
                      <Typography variant="body1">{task?.title}</Typography>
                    </Box>
                    <Box>
                      <Button sx={{color:"black", minWidth:"0"}} onClick={() => handleEditTask(task?.id)}>
                        <FaRegEdit size={20} className="edit-btn" />
                      </Button>
                      &nbsp;&nbsp;
                      <Button sx={{color:"black", minWidth:"0"}} onClick={() => deleteTask(task?.id)}><CgCloseO size={20} className="close-btn" /></Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      paddingLeft: "4%",
                      paddingTop: "1%",
                      paddingRight: "4%",
                    }}
                  >
                    <Typography>{task?.description}</Typography>
                  </Box>
                </Box>
              )
            )
          ) : (
            <Box> No Task Available </Box>
          )}
          {taskData.length > 0 && (
            <div style={{ marginTop: "20px" }}>
              {/* Pagination controls */}
              <Button
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#232674",
                  padding: "0",
                  marginRight: "10px",
                  "&:hover": { color: "white", backgroundColor: "#232674" },
                }}
                onClick={() => goToPrevPage(currentPage - 1)}
              >
                prev
              </Button>
              <span>{`Page ${currentPage} of ${totalPages}`}</span>
              <Button
                sx={{
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "#232674",
                  padding: "0",
                  marginLeft: "10px",
                  "&:hover": { color: "white", backgroundColor: "#232674" },
                }}
                onClick={() => goToPage(currentPage + 1)}
              >
                next
              </Button>
              <div style={{ marginTop: "10px", height: "15px" }}></div>
            </div>
          )}
        </Box>
      )}
    </>
  );
};

export default Tasks;
