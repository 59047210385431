import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./component/Home";
import { BrowserRouter as Router , Routes , Route, Navigate } from 'react-router-dom';
import Table from "./component/Table";
import NewLead from './component/NewLead';
import Login from "./component/Login";
import Dashboard from './component/Dashboard/Dashboard';
import { useState } from 'react';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Function to handle login
  const handleLogin = () => {
    // Assuming login is successful, update isLoggedIn state
    setIsLoggedIn(true);
    console.log("i tapped")
  };

  const handleLogout = () => {
    // Simulated logout logic
    setIsLoggedIn(false);
  };

  console.log("initallogin =", isLoggedIn)

  return (
    <Router>
    <Routes>
      <Route path='/login' element={<Login onLogin={handleLogin} isLoggedIn={isLoggedIn} />} />
      {isLoggedIn ? (
        <>
          <Route path='/home' element={<Dashboard isLoggedIn={isLoggedIn} onLogout={handleLogout} />} />
          <Route path='/newLeads' element={<NewLead />} />
          {/* Add more private routes as needed */}
        </>
      ) : (
        // Redirect to Login page if not logged in
        <Route path='*' element={<Navigate to='/login' />} />
      )}
    </Routes>
  </Router>
  );
}

export default App;
