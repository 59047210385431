import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./newLeads.css";


const NewLead = () => {
  const navigate = useNavigate();
  const [isCategory, setIsCategory] = useState(null)

  const back = () => {
    navigate('/home');
  };

  const [formData, setFormData] = useState({
    "first_name": "",
    "email": "",
    "category": "" ,
    "category_name": "",
    "hotel_name": "",
    "hotel_address": "",
    "phone_number": "",
    "url": "",
    "country": "",
"description":"",
"agent":localStorage.getItem("id")
  });

  const getCategories = async() => {
    try {
      const res = await axios.get(
        "https://crm.voyagerstales.com/categories/api",
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );

      setIsCategory(res?.data?.results)
      
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getCategories()
  },[])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.country || formData.country.trim() === "") {
      alert("Country field may not be blank");
    }
    else if(!formData.first_name || formData.first_name.trim() === ""){
      alert("Owner Name field may not be blank");
    }
    else if(!formData.hotel_name || formData.hotel_name.trim() === ""){
      alert("Property Name field may not be blank");
    }
    else if(!formData.phone_number || formData.phone_number.trim() === ""){
      alert("Phone number field may not be blank");
    } else {
    try {
      await axios.post(
        'https://crm.voyagerstales.com/leads/api/',
        formData,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          }
        }
      );
      navigate('/home');
    } catch (error) {
      console.error('Error:', error);
      if (error?.response && error?.response?.data && error?.response?.data?.message) {
        // Show the error message in a dialog box
        window.alert(error?.response?.data?.message);
      }
      // else {
      //   // Show a generic error message
      //   window.alert(error.response.data.country ? "Country Field may not be blank" : error.response.data.first_name ? "Owner Name field may not be blank" : error.response.data.hotel_name ? "Property Name may not be blank" : error.response.data.phone_number ? "Phone number may not be blank" : "An error has occured");
      // }
    }
  };
}

  return (
    <div className='NewLeads'>
      <div className='form'>
        <div className='headings'>
          <h5>Add New Lead</h5>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={back}>
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 9L15 15" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15 9L9 15" stroke="#1E1E1E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className='contents'>
          <input className='input' type="text" name="first_name" placeholder='Owner Name' value={formData.first_name} onChange={handleChange} />
          <input className='input' type="email" name="email" placeholder='Email address' value={formData.email} onChange={handleChange} />
          <input className='input' type="text" name="country" placeholder='Country' value={formData.country} onChange={handleChange} />
          <input className='input' type="text" name="hotel_name" placeholder='Property Name' value={formData.hotel_name} onChange={handleChange} />
          <input className='input' type="text" name="url" placeholder='URL' value={formData.url} onChange={handleChange} />
          <input className='input' type="number" name="phone_number" placeholder='Phone Number' value={formData.phone_number} onChange={handleChange} />
          <textarea className='textArea' name="description" placeholder='Description' value={formData.description} onChange={handleChange} />
          <textarea className='textArea' name="hotel_address" placeholder='Property Address' value={formData.hotel_address} onChange={handleChange} />
          <select className='input' id="category" placeholder="Select Category" name="category" onChange={handleChange}>
            <option value="" >Select a category</option>
              {isCategory?.map((category) => (
                <option key={category?.id} value={category?.id}>{category?.name}</option>
              ))}
          </select>
        </div>
        <div className='button'>
          <button className='cancel' onClick={back}>Cancel</button>
          <button className='save' onClick={handleSubmit}>Save New Lead<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.50008 10.1663L6.16675 5.49967L1.50008 0.833008" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewLead;

