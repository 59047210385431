import { useState } from "react";
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import bg from "../Assests/background.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../Assests/background.jpg";
import "./login.css";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import logo from "../Assests/img.png"
import { Navigate } from "react-router-dom";

const Login = ({ onLogin, isLoggedIn }) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://crm.voyagerstales.com/account/login",
        {
          email: email,
          password: password,
        }
      );
      if (response.id === localStorage.getItem("id")) {
        onLogin()
        navigate("/home");
      } else {
        localStorage.setItem("user", response?.data?.user);
        localStorage.setItem("id", response?.data?.user?.id);
        localStorage.setItem("token", response?.data?.user?.Token);
        localStorage.setItem("name", response?.data?.user?.first_name);
        localStorage.setItem("email", response?.data?.user?.email);
        localStorage.setItem("mobile", response?.data?.user?.mobile);
        localStorage.setItem(
          "is_superuser",
          response?.data?.user?.is_superuser
        );
        onLogin()
        navigate("/home");
      }
    } catch (error) {
      setError("Invalid username or password");
      alert("invalid username or password");
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/home" />;
  }
  
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection:"column",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          display:"flex",
          flexDirection:"column",
          justifyContent:"center",
          alignItems:"center",
          marginTop:"3rem",
          marginBottom:"-1rem"
        }}
      >
        <Box
          sx={{width:"5rem", height:"5rem",marginBottom:".5rem" }}
        >
          <img src={logo} style={{width:"5rem", height:"5rem", borderRadius:"2.5rem"}}/>
        </Box>
        <Box>
        <Typography
          variant="h3"
          fontWeight={400}
          sx={{
            color: "white",
            fontFamily: " Racing Sans One",
          }}
        >
          Voyagers Tales
        </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          height: "26rem",
          width: "25.5rem",
          padding: "2.5rem 1.875rem",
          mb: 7,
          mt: 7,
          backgroundColor: "#fcfcfc",
          borderRadius: "1.25rem",
        }}
      >
        <Typography
          variant="body1"
          fontWeight={700}
          sx={{
            fontSize: ".8rem",
            color: "primaryText.main",
            lineHeight: "1.408rem",
          }}
        >
          WELCOME BACK
        </Typography>
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{
            fontSize: "1.563rem",
            color: "primaryText.main",
            lineHeight: "2.75rem",
          }}
        >
          Log In to your Account
        </Typography>
        {/* TABS */}
        <Box
          sx={{
            width: "100%",
            mt: "2rem",
            mb: "2rem",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <form onSubmit={handleLogin}>
              <Box
                gap={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  label="Email"
                  variant="outlined"
                  autoComplete="false"
                  type="email"
                  name="email"
                  required
                  value={email}
                  onChange={(e) => handleEmailChange(e)}
                  InputLabelProps={{ style: { marginTop: "0" } }}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "3.5rem",
                      borderRadius: ".625rem",
                    },
                  }}
                />

                <TextField
                  label="Password "
                  variant="outlined"
                  name="password"
                  required
                  value={password}
                  onChange={(e) => handlePasswordChange(e)}
                  InputLabelProps={{ style: { marginTop: "0" } }}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      height: "3.5rem",
                      borderRadius: ".625rem",
                    },
                  }}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <RemoveRedEyeOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Button
                type="submit"
                endIcon={<ArrowForwardOutlinedIcon />}
                sx={{
                  my: "2rem",
                  textTransform: "capitalize",
                  backgroundColor: "#232674",
                  color: "white",
                  width: "100%",
                  height: "3.688rem",
                  fontSize: "1rem",
                  fontWeight: "400",
                  borderRadius: ".625rem",
                  "&:hover": {
                    backgroundColor: "#232674",
                    color: "white",
                  },
                }}
              >
                Login
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
